export class LoadMoreContestApplicationsForContent
{
    constructor(id, loadMoreUrl) {
        this.id = id;
        this.loadMoreUrl = loadMoreUrl;

        this.container = document.getElementById('contest_application_container');
        this.loader = document.getElementById('contest_content_application_loader');
        this.loaderCounter = document.getElementById('contest_content_application_loader_counter');
        this.loaderBar = document.getElementById('contest_content_application_loader_bar');
        this.loaderButton = document.getElementById('contest_content_application_loader_button');

        this.#bindListeners();
    }

    #bindListeners() {
        this.loaderButton.addEventListener("click", () => this.#loadMoreApplications());
    }

    #loadMoreApplications() {
        this.#fetchReviewsForPage(this.loader.dataset.nextPage)
            .then(res => res.json())
            .then(json => {
                this.container.innerHTML += json.applications;
                this.loaderCounter.textContent = json.viewed;
                this.loaderBar.style.width = json.fill;
                refreshFsLightbox();
                if (json.can_load_more) {
                    this.loader.dataset.nextPage = parseInt(this.loader.dataset.nextPage) + 1;
                    return;
                }

                this.loaderButton.classList.add('d-none');
            });
    }

    async #fetchReviewsForPage(page) {
        const data = new FormData();
        data.append('special_action', 'load-more')
        data.append('page', page);

        const headers = new Headers();
        headers.set('X-Requested-With', 'XMLHttpRequest')

        const init = {
            method: 'POST',
            body: data,
            headers: headers
        };

        return fetch(this.loadMoreUrl, init);
    }
}
