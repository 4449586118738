export class LoadMoreContestApplications {
    constructor(container, loader, widgetId, loadMoreUrl) {
        this.container = container;
        this.loader = loader;
        this.widgetId = widgetId;
        this.loadMoreUrl = loadMoreUrl;

        this.fetching = false;

        if (loader) {
            this.loaderCounter = document.getElementById('contest_application_loader_counter_' + widgetId);
            this.loaderBar = document.getElementById('contest_application_loader_bar_' + widgetId);
            this.loaderButton = document.getElementById('contest_application_loader_button_' + widgetId);
        }

        this.#bindListeners();
    }

    #bindListeners() {
        if (this.loader) {
            this.loaderButton.addEventListener("click", () => this.#loadMoreApplications());
        }
    }

    #loadMoreApplications() {
        this.#fetchReviewsForPage(this.loader.dataset.nextPage)
            .then(res => res.json())
            .then(json => {
                this.container.innerHTML += json.applications;
                this.loaderCounter.textContent = json.viewed;
                this.loaderBar.style.width = json.fill;
                refreshFsLightbox();
                if (json.can_load_more) {
                    this.loader.dataset.nextPage = parseInt(this.loader.dataset.nextPage) + 1;
                    return;
                }

                this.loaderButton.classList.add('d-none');
            });
    }

    async #fetchReviewsForPage(page) {
        const data = new FormData();
        data.append('widget_id', this.widgetId);
        data.append('page', page);

        const init = {
            method: 'POST',
            body: data
        };

        return fetch(this.loadMoreUrl, init);
    }

}
