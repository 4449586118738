export class ContestApplicationForm {
    static render() {
        const forms = document.querySelectorAll('[data-contest-application-form]');
        forms.forEach(form => {
            new ContestApplicationForm(form);
        });
    }

    constructor(formContainer) {
        this.formContainer = formContainer;
        this.data = {};
        this.messageErrorGeneral = 'Sorry. An error occured.';
        this.scrolled = false;
        this.shouldScroll = false;
        this.form = this.formContainer.getElementsByTagName('form')[0];
        this.addFileValidator();
        this.form.addEventListener('submit', this.onSubmit);
    }

    onSubmit = async (event) => {
        event.preventDefault();
        this.showOverlay();
        this.clearFieldErrors();
        this.disableSubmitButton();

        try {
            await this.executeCaptcha();

            const response = await this.send();
            const responseData = await response.json();

            this.clearFormAlerts();

            if (responseData.success) {
                this.onSuccess(responseData);
            } else {
                this.onError(responseData);
            }
        } catch (error) {
            this.showFormError(this.messageErrorGeneral);
            console.error(error);
        } finally {
            this.hideOverlay();
            this.enableSubmitButton();
        }
    };

    send() {
        const action = this.form.getAttribute('action');
        const payload = new FormData();
        const fields = {};

        const inputs = [...this.form.querySelectorAll('input, select, textarea')]
        inputs.forEach(input => {
            const type = input.getAttribute('type')
            if (type && type === 'submit') return
            if (type && type === 'button') return

            const name = input.getAttribute('name')
            if (name === 'g-recaptcha-response') return

            if (name && !fields.hasOwnProperty(name))
                fields[name] = undefined
        })

        const data = new FormData(this.form);
        for (let [key, value] of data.entries()) {
            const field = this.getField(key);
            if (key.endsWith('[]')) {
                if (!fields[key]) {
                    fields[key] = [];
                }
                fields[key].push(value);
            } else {
                fields[key] = value;
            }

            payload.append(key, value);
        }

        this.setFields(fields);

        return fetch(action, {
            method: 'POST',
            body: payload,
            headers: {
                'Accept': 'application/json',
            },
        });
    }

    onSuccess(data) {
        if (data.redirect) {
            window.location.href = data.redirect;
            return;
        }

        if (data.message) {
            this.showFormSuccess(data.message);
        }

        this.form.classList.add('d-none');
    }

    onError(data) {
        if (data.errors && data.errors?.form) {
            for (const [key, error] of Object.entries(data.errors.form)) {
                this.showFormError(error);
            }
        }

        this.showFormError(data.message ?? this.messageErrorGeneral);

        if (data.errors && data.errors?.fields) {
            this.shouldScroll = true;
            for (const [field, errors] of Object.entries(data.errors.fields)) {
                for (const message of Object.values(errors)) {
                    this.showFieldError(field, message);
                }
            }
            this.scrolled = false
            this.shouldScroll = false
        }
    }

    addFileValidator() {
        const inputs = this.form.querySelectorAll('.js-drag-and-drop-helper-input');

        inputs.forEach(input => {
            const maxAllowedSize = input.getAttribute("data-upload-max-size") * 1024 ** 2;

            const self = this;
            input.addEventListener("input", function () {
                Array.from(this.files).forEach((file) => {
                    if (file.size > maxAllowedSize) {
                        self.showFieldError(input.name, `Dodanie plików nie powiodło się, któryś z plików przekracza dozwolony rozmiar ${input.getAttribute("data-upload-max-size")}MB.`);
                        input.value = "";
                        setTimeout(() => self.clearFieldError(input.name), 5000);
                    }
                });
            });
        });
    }

    clearFieldErrors() {
        const fields = this.formContainer.querySelectorAll('input, select, textarea');
        for (const field of fields) {
            this.clearFieldError(field.getAttribute('name'));
        }
    }

    clearFieldError(fieldName) {
        const field = this.getField(fieldName);
        if (!field) return;

        field.classList.remove('is-invalid');

        const container = this.getFieldErrorsContainer(field);
        if (container) container.remove();
    }

    showFieldError(fieldName, message) {
        try {
            const field = this.getField(fieldName);
            if (!field) return;

            if (field.type !== 'checkbox' && field.type !== 'radio')
                field.classList.add('is-invalid');

            const container = this.createFieldErrorsContainer(field);
            const errorElement = document.createElement('small');
            errorElement.className = 'd-block text-danger';
            errorElement.innerHTML = message;

            container.append(errorElement);
            if (this.shouldScroll && !this.scrolled) {
                // field.scrollIntoView({ behavior: "smooth"});
                const yOffset = -100;
                const y = field.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                this.scrolled = true
            }
        } catch (e) {
            console.log(e);
        }
    }

    createFieldErrorsContainer(field) {
        if (this.getFieldErrorsContainer(field)) return this.getFieldErrorsContainer(field);

        const fieldErrorsContainer = document.createElement('div');
        fieldErrorsContainer.setAttribute('id', this.getFieldErrorsContainerId(field));

        if (field.type === 'checkbox' || field.type === 'radio') {
            field.closest('div').append(fieldErrorsContainer);
        } else if (field.tagName.toLowerCase() === 'select') {
            let container = field.closest('.choices');

            if (container) {
                container.after(fieldErrorsContainer);
            }
        } else if (field.type === 'file') {
            field.closest('.js-drag-and-drop').after(fieldErrorsContainer);
        } else if (field.hasAttribute('data-tel-input')) {
            field.closest('.iti').after(fieldErrorsContainer)
        } else {
            field.after(fieldErrorsContainer);
        }

        return fieldErrorsContainer;
    }

    getField(name) {
        const fields = this.formContainer.querySelectorAll('input, select, textarea');

        for (const field of fields) {
            if (field.getAttribute('name') === name || field.getAttribute('name') === `${name}[]`) return field;
        }

        return null;
    }

    getFieldErrorsContainer(field) {
        return document.getElementById(this.getFieldErrorsContainerId(field));
    }

    getFieldErrorsContainerId(field) {
        const formName = this.getFormName();
        const fieldId = field.getAttribute('id');
        return `${formName}_${fieldId}_errors`;
    }

    hideOverlay() {
        this.toggleOverlay(false)
    }

    showOverlay() {
        this.toggleOverlay(true)
    }

    toggleOverlay(show) {
        const overlay = this.formContainer.querySelector('.contest-application-form-overlay')
        if (!overlay) return

        if (show) {
            overlay.classList.add('show')
        } else {
            overlay.classList.remove('show')
        }
    }

    showFormAlert(type, message) {
        const element = document.createElement('div');
        element.className = `alert alert-${type}`;
        element.innerHTML = message;
        this.formContainer.prepend(element);
    }

    clearFormAlerts() {
        while (this.formContainer.firstElementChild && this.formContainer.firstElementChild.classList.contains('alert')) {
            this.formContainer.firstElementChild.remove();
        }
    }

    showFormError(message) {
        this.showFormAlert('danger', message);
    }

    showFormSuccess(message) {
        const element = document.createElement('div');
        element.innerHTML = message;
        this.formContainer.prepend(element);
    }

    getFormName() {
        return this.form.getAttribute('name');
    }

    setFields(fields) {
        this.data.fields = {...fields};
    }

    enableSubmitButton() {
        this.setSubmitButtonEnabled(true);
    }

    disableSubmitButton() {
        this.setSubmitButtonEnabled(false);
    }

    getSubmitButton() {
        return [...this.formContainer.querySelectorAll('input[type=submit], button[type=submit]')];
    }

    setSubmitButtonEnabled(enabled) {
        const buttons = this.getSubmitButton();
        if (!buttons) return;

        buttons.forEach(button => {
            if (enabled) {
                button.removeAttribute('disabled');
            } else {
                button.setAttribute('disabled', '');
            }
        })
    }

    executeCaptcha() {
        return new Promise((resolve, reject) => {
            const publicKey = this.form.getAttribute('data-recaptcha-key');
            if (!publicKey) return resolve();

            grecaptcha.ready(() => {
                grecaptcha.execute(publicKey, { action: 'submit' })
                    .then(this.insertTokenElement.bind(this))
                    .then(resolve)
                    .catch(reject);
            });
        });

    }

    insertTokenElement(token) {
        const prevResponse = this.form.querySelector('input[name="g-recaptcha-response"]')
        if (prevResponse) prevResponse.remove()

        const tokenInput = document.createElement('input');
        tokenInput.type = 'hidden';
        tokenInput.name = 'g-recaptcha-response';
        tokenInput.value = token;

        this.form.insertBefore(tokenInput, this.form.firstElementChild);
    }
}
